import React from 'react';

const LogoRefrisolution = () => {
  return (
    <svg
      width='36'
      height='40'
      viewBox='0 0 36 40'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      {/* <desc> substitui a metatag alt */}
      <desc>Logo Refrisolution</desc>
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M13.4877 4.3641C14.619 4.3641 15.5347 5.28222 15.5347 6.41473C15.5347 7.54845 14.619 8.46657 13.4877 8.46657C12.3576 8.46657 11.4419 7.54845 11.4419 6.41473C11.4419 5.28222 12.3576 4.3641 13.4877 4.3641ZM21.6272 4.3641C22.7573 4.3641 23.6742 5.28222 23.6742 6.41473C23.6742 7.54845 22.7573 8.46657 21.6272 8.46657C20.4972 8.46657 19.5815 7.54845 19.5815 6.41473C19.5815 5.28222 20.4972 4.3641 21.6272 4.3641ZM16.5098 3.81177C15.8896 3.44113 15.5117 2.77374 15.5117 2.05063C15.5117 0.91812 16.4274 0 17.5575 0C18.6888 0 19.6045 0.91812 19.6045 2.05063C19.6045 2.75678 19.2484 3.37936 18.7069 3.74758C17.675 4.45131 17.5066 8.3515 18.6197 9.02616C19.2108 9.3859 19.6045 10.0363 19.6045 10.78C19.6045 11.9125 18.6888 12.8307 17.5575 12.8307C16.4274 12.8307 15.5117 11.9125 15.5117 10.78C15.5117 10.0315 15.9114 9.37621 16.5098 9.0189C17.4909 8.44113 17.4957 4.40528 16.5098 3.81177Z'
        fill='#69C9D2'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M33.1049 15.7134C33.6694 16.6945 33.3339 17.9494 32.3552 18.515C31.3765 19.0819 30.1241 18.7464 29.5597 17.7653C28.994 16.7842 29.3295 15.5293 30.3082 14.9625C31.2881 14.3968 32.5393 14.7323 33.1049 15.7134ZM31.0228 10.9956C31.0337 10.2725 31.4201 9.61119 32.0463 9.24903C33.025 8.68217 34.2762 9.0189 34.8419 10C35.4063 10.9799 35.072 12.2347 34.0921 12.8016C33.4829 13.1541 32.767 13.1565 32.1771 12.8706C31.0531 12.3268 27.5987 14.1315 27.572 15.4336C27.5563 16.1265 27.1905 16.7951 26.5485 17.1657C25.5698 17.7326 24.3186 17.3958 23.753 16.4147C23.1873 15.4336 23.5228 14.18 24.5027 13.6131C25.1495 13.2389 25.915 13.2594 26.5243 13.5986C27.5139 14.1618 31.0034 12.1487 31.0228 10.9956ZM29.0352 8.64947C29.5996 9.63057 29.2641 10.8854 28.2854 11.4511C27.3067 12.0179 26.0555 11.6824 25.4899 10.7013C24.9254 9.7202 25.2597 8.46536 26.2396 7.89971C27.2183 7.33285 28.4695 7.66836 29.0352 8.64947Z'
        fill='#34C5F0'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M33.1049 24.2854C32.5393 25.2665 31.2881 25.602 30.3082 25.0363C29.3295 24.4695 28.994 23.2146 29.5597 22.2347C30.1241 21.2536 31.3765 20.9169 32.3552 21.4838C33.3339 22.0494 33.6694 23.3043 33.1049 24.2854ZM29.0352 31.3493C28.4695 32.3304 27.2183 32.6659 26.2396 32.1003C25.2597 31.5334 24.9254 30.2786 25.4899 29.2975C26.0555 28.3164 27.3067 27.9809 28.2854 28.5477C29.2641 29.1134 29.5996 30.3682 29.0352 31.3493ZM32.0705 27.1839C32.7016 26.8314 33.4671 26.8362 34.0921 27.1984C35.072 27.764 35.4063 29.0189 34.8419 30C34.2762 30.9811 33.025 31.3166 32.0463 30.751C31.4359 30.3973 31.0761 29.7771 31.0277 29.1231C30.9368 27.8755 27.6495 25.7788 26.5097 26.4075C25.9041 26.7406 25.1447 26.7575 24.5027 26.3857C23.5228 25.8188 23.1873 24.5652 23.753 23.5841C24.3186 22.603 25.5698 22.2662 26.5485 22.8331C27.1953 23.2074 27.5611 23.882 27.572 24.5809C27.5805 25.7207 31.0652 27.7422 32.0705 27.1827V27.1839Z'
        fill='#00AFEF'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M21.6272 35.6347C20.4972 35.6347 19.5815 34.7166 19.5815 33.5841C19.5815 32.4503 20.4972 31.5322 21.6272 31.5322C22.7585 31.5322 23.6742 32.4503 23.6742 33.5841C23.6742 34.7166 22.7585 35.6347 21.6272 35.6347ZM18.6064 36.187C19.2266 36.5577 19.6045 37.225 19.6045 37.9482C19.6045 39.0819 18.6888 40 17.5575 40C16.4274 40 15.5117 39.0819 15.5117 37.9482C15.5117 37.242 15.8678 36.6194 16.4092 36.2512C17.4412 35.5487 17.6096 31.6473 16.4964 30.9726C15.9053 30.6129 15.5117 29.9625 15.5117 29.2188C15.5117 28.0862 16.4274 27.1681 17.5575 27.1681C18.6888 27.1681 19.6045 28.0862 19.6045 29.2188C19.6045 29.9685 19.2048 30.6226 18.6064 30.9811C17.6253 31.5577 17.6205 35.5935 18.6064 36.187ZM13.4889 35.6347C12.3588 35.6347 11.4419 34.7166 11.4419 33.5841C11.4419 32.4503 12.3588 31.5322 13.4877 31.5322C14.619 31.5322 15.5347 32.4503 15.5347 33.5841C15.5347 34.7166 14.619 35.6347 13.4889 35.6347Z'
        fill='#69C9D2'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M2.01119 24.2854C1.44675 23.3043 1.78227 22.0494 2.76095 21.4838C3.73963 20.9169 4.99084 21.2536 5.55649 22.2347C6.12214 23.2158 5.78663 24.4695 4.80795 25.0363C3.82805 25.602 2.57684 25.2665 2.01119 24.2854ZM4.09332 29.0031C4.08241 29.7263 3.69603 30.3888 3.06982 30.751C2.09114 31.3166 0.839923 30.9811 0.274274 30C-0.290164 29.0189 0.0441379 27.764 1.02403 27.1984C1.63329 26.8447 2.35034 26.8423 2.939 27.1281C4.06303 27.6732 7.51749 25.8685 7.54414 24.5652C7.55989 23.8723 7.92447 23.2049 8.56764 22.8331C9.54632 22.2662 10.7975 22.603 11.3632 23.5841C11.9288 24.5652 11.5933 25.8188 10.6134 26.3857C9.96662 26.7599 9.20111 26.7406 8.59186 26.4002C7.60228 25.837 4.1127 27.85 4.09332 29.0031ZM6.08096 31.3493C5.51652 30.3682 5.85204 29.1134 6.83072 28.5477C7.8094 27.9809 9.06061 28.3176 9.62626 29.2975C10.1907 30.2786 9.8564 31.5334 8.8765 32.1003C7.89782 32.6659 6.64661 32.3304 6.08096 31.3493Z'
        fill='#34C5F0'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M6.08096 8.64947C6.64661 7.66836 7.89782 7.33285 8.8765 7.89971C9.8564 8.46536 10.1907 9.7202 9.62626 10.7013C9.06061 11.6824 7.8094 12.0179 6.83072 11.4523C5.85204 10.8854 5.51652 9.63057 6.08096 8.64947ZM3.04559 12.8161C2.41454 13.1674 1.64903 13.1638 1.02403 12.8016C0.0441379 12.2347 -0.290164 10.9799 0.274274 10C0.839923 9.0189 2.09114 8.68217 3.06982 9.24903C3.68028 9.6015 4.04002 10.2217 4.08847 10.8769C4.17931 12.1233 7.46662 14.22 8.6064 13.5913C9.21202 13.2582 9.97146 13.2413 10.6134 13.6131C11.5933 14.18 11.9288 15.4336 11.3632 16.4147C10.7975 17.3958 9.54632 17.7326 8.56764 17.1657C7.91962 16.7914 7.55504 16.1168 7.54414 15.4191C7.53566 14.2781 4.05092 12.2565 3.04559 12.8161ZM2.01119 15.7134C2.57684 14.7335 3.82805 14.3968 4.80795 14.9637C5.78663 15.5293 6.12214 16.7842 5.55649 17.7653C4.99084 18.7464 3.73963 19.0819 2.76095 18.515C1.78227 17.9494 1.44675 16.6945 2.01119 15.7134Z'
        fill='#00AFEF'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M17.5587 16.2318C19.6348 16.2318 21.3184 17.9179 21.3184 20C21.3184 22.0809 19.6348 23.767 17.5587 23.767C15.4814 23.767 13.7978 22.0809 13.7978 20C13.7978 17.9179 15.4814 16.2318 17.5587 16.2318Z'
        fill='#00AFEF'
      />
    </svg>
  );
};

export default LogoRefrisolution;
