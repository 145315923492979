import React, { useRef } from 'react';
import TituloPagina from '../Common/TituloPagina';
import quemSomos from './QuemSomos.module.css';
import porque from './PorQue.module.css';
import valores from './Valores.module.css';
import { useNavigate } from 'react-router-dom';
import imagemTecnicoArCondicionado from '../../assets/PaginaSobreNos/tecnico-e-ar-condicionado.jpg';
import MissaoIcon from '../Common/svg/MissaoIcon';
import ValoresIcon from '../Common/svg/ValoresIcon';
import VisaoIcon from '../Common/svg/VisaoIcon';

const PaginaSobreNos = ({ titulo, descricao }) => {
  const navigate = useNavigate();

  const irParaPaginaPMOC = () => {
    navigate('/pmoc');
  };

  React.useEffect(() => {
    document.title = 'Refrisolution - ' + titulo;
    document
      .querySelector("meta[name='description']")
      .setAttribute('content', descricao);
  }, [titulo, descricao]);

  const quemSomosTexto = useRef();

  React.useEffect(() => {
    quemSomosTexto.current.classList.add('animarDoTopo500');
  }, []);

  const ArrowDecorator = () => {
    return (
      <svg
        width='199'
        height='99'
        viewBox='0 0 199 99'
        fill='none'
        xmlns='http://www.w3.org/2000/svg'
        className={quemSomos.arrowDecorator}
      >
        <mask
          id='mask0_202_353'
          style={{ maskType: 'alpha' }}
          maskUnits='userSpaceOnUse'
          x='51'
          y='0'
          width='99'
          height='99'
        >
          <rect x='51' width='99' height='99' fill='#D9D9D9' />
        </mask>
        <g mask='url(#mask0_202_353)'>
          <path
            d='M75.959 78.375L96.584 49.5L75.959 20.625H86.0652L106.69 49.5L86.0652 78.375H75.959ZM100.503 78.375L121.128 49.5L100.503 20.625H110.609L131.234 49.5L110.609 78.375H100.503Z'
            fill='#00172E'
          />
        </g>
        <mask
          id='mask1_202_353'
          style={{ maskType: 'alpha' }}
          maskUnits='userSpaceOnUse'
          x='100'
          y='0'
          width='99'
          height='99'
        >
          <rect x='100' width='99' height='99' fill='#D9D9D9' />
        </mask>
        <g mask='url(#mask1_202_353)'>
          <path
            d='M124.959 78.375L145.584 49.5L124.959 20.625H135.065L155.69 49.5L135.065 78.375H124.959ZM149.503 78.375L170.128 49.5L149.503 20.625H159.609L180.234 49.5L159.609 78.375H149.503Z'
            fill='#00172E'
          />
        </g>
        <mask
          id='mask2_202_353'
          style={{ maskType: 'alpha' }}
          maskUnits='userSpaceOnUse'
          x='0'
          y='0'
          width='99'
          height='99'
        >
          <rect width='99' height='99' fill='#D9D9D9' />
        </mask>
        <g mask='url(#mask2_202_353)'>
          <path
            d='M24.959 78.375L45.584 49.5L24.959 20.625H35.0652L55.6902 49.5L35.0652 78.375H24.959ZM49.5027 78.375L70.1277 49.5L49.5027 20.625H59.609L80.234 49.5L59.609 78.375H49.5027Z'
            fill='#00172E'
          />
        </g>
      </svg>
    );
  };

  return (
    <main>
      <TituloPagina
        texto='Conheça mais sobre a '
        textoEnfase='Refrisolution.'
      />

      <section className={quemSomos.container}>
        <div className={quemSomos.leftSide} ref={quemSomosTexto}>
          <h3 className={quemSomos.titulo}>🏢 Quem somos</h3>
          <p className={quemSomos.texto}>
            Empresa de <span className={quemSomos.semiBold}>Sorocaba</span>,
            atuando na <span className={quemSomos.semiBold}>instalação</span> e{' '}
            <span className={quemSomos.semiBold}>manutenção</span> de
            equipamentos de ar condicionado. Garantindo o respeito ao meio
            ambiente e a qualidade no tratamento do ar.
          </p>
          <p className={quemSomos.texto}>
            A <span className={quemSomos.semiBold}>Refrisolution</span> vem se
            destacando ao longo dos anos por desenvolver um trabalho baseado na{' '}
            <span className={quemSomos.semiBold}>
              competência e honestidade
            </span>{' '}
            junto a seus clientes. Contamos com uma{' '}
            <span className={quemSomos.semiBold}>
              equipe técnica certificada
            </span>{' '}
            pelas melhores instituições, e treinados pelos fabricantes para os
            serviços de manutenção e instalação em equipamentos de ar
            condicionado, bem como desenvolvemos projetos com as melhores
            soluções atendendo, assim, à construtoras, arquitetos e cliente
            final.
          </p>

          <ArrowDecorator />
        </div>
        <img
          src={imagemTecnicoArCondicionado}
          alt='Técnico fazendo manutenção em ar condicionado'
          className={quemSomos.imagem}
        />
      </section>

      <div className={porque.containerContainer}>
        <section className={porque.container}>
          <div className={porque.leftSide}>
            <h3 className={porque.titulo}>
              Por que <span className={porque.textoEnfase}>Refrisolution?</span>
            </h3>
          </div>
          <div className={porque.divisor}></div>
          <div className={porque.rightSide}>
            <p className={porque.texto}>
              A <span className={porque.sb}>Refrisolution</span> conta com
              técnico capacitado e certificado pelo{' '}
              <span className={porque.sb}>SENAI-SP</span> Ipiranga, uma das
              melhores instituições da América Latina no ramo, certificado para
              execução de{' '}
              <span className={porque.sb}>
                serviços em centrais de climatização
              </span>{' '}
              como também em VRV/VRF.
            </p>
            <p className={porque.texto}>
              Além da elaboração e execução do{' '}
              <span onClick={irParaPaginaPMOC} className={porque.pmoc}>
                PMOC
              </span>
              , expedimos o Termo de Responsabilidade Técnica (
              <span className={porque.sb}>TRT</span>) correspondente junto ao{' '}
              <span className={porque.sb}>CFT/CRT-SP</span> (Conselho Federal e
              regional dos Técnicos Industriais de São Paulo), assegurando a
              conformidade às normas técnicas.
            </p>
          </div>
        </section>
      </div>

      <div className='linhaDivisoriaGeral' />
      <section className={valores.background}>
        <div className={valores.container}>
          <div className={valores.leftSide}>
            <div className={valores.card}>
              <h4 className={valores.titulo}>Missão</h4>{' '}
              <p className={valores.texto}>
                Proporcionar conforto em climatização, assim como, soluções
                técnicas específicas, através de processos ágeis, seguros e
                eficazes, com o objetivo de superar quaisquer expectativas que
                os nossos clientes possam ter.
              </p>
              <div className={valores.icone}>
                <MissaoIcon darkVersion={true} />
              </div>
            </div>
            <div className={valores.card}>
              <h4 className={valores.titulo}>Valores</h4>{' '}
              <p className={valores.texto}>
                Respeito, seriedade, profissionalismo e inovação.
              </p>
              <div className={valores.icone}>
                <ValoresIcon darkVersion={true} />
              </div>
            </div>
          </div>
          <div className={valores.rightSide}>
            <div className={valores.card}>
              <h4 className={valores.titulo}>Visão</h4>
              <p className={valores.texto}>
                Ser referência em soluções em Sistemas de Ar Condicionado, além
                de oferecer um serviço aos clientes, que se diferencie pela
                qualidade e confiabilidade.
              </p>
              <div className={valores.icone}>
                <VisaoIcon darkVersion={true} />
              </div>
            </div>
            <div className={valores.faixa}>
              <p className={valores.faixaTexto}>
                Assim buscamos nos diferenciar, aplicando constantemente
                serviços de excelência que se adéquam aos mercados mais
                exigentes.
              </p>
            </div>
          </div>
        </div>
      </section>
      <div className={`linhaDivisoriaGeral ${valores.linhaDivisoriaFinal}`} />
    </main>
  );
};

export default PaginaSobreNos;
