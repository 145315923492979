import React from 'react';
import style from './PaginaAparelhoIdeal.module.css';
import TituloPagina from '../Common/TituloPagina';
import ButtonWithArrow from '../Common/ButtonWithArrow';

const PaginaAparelhoIdeal = ({ titulo, descricao }) => {
  const [comprimento, setComprimento] = React.useState('');
  const [largura, setLargura] = React.useState('');
  const [quantidadePessoas, setQuantidadePessoas] = React.useState(1);
  const [incidenciaSolar, setIncidenciaSolar] = React.useState('pouca');
  const [area, setArea] = React.useState(0);
  const [resultado, setResultado] = React.useState(0);

  React.useEffect(() => {
    document.title = 'Refrisolution - ' + titulo;
    document
      .querySelector("meta[name='description']")
      .setAttribute('content', descricao);
  }, [titulo, descricao]);

  React.useEffect(() => {
    if (comprimento && largura) {
      setArea(comprimento * largura);
    }
  }, [comprimento, largura]);

  const handleCalcularBTU = (event) => {
    event.preventDefault();

    if (area && quantidadePessoas) {
      const incidenciaSolarMultiplicador =
        incidenciaSolar === 'pouca' ? 600 : 800;
      const calculoBTU =
        incidenciaSolarMultiplicador * area +
        (quantidadePessoas - 1) * incidenciaSolarMultiplicador;

      setResultado(calculoBTU);
    }
  };

  return (
    <main>
      <TituloPagina
        texto='Quer saber qual o '
        textoEnfase='aparelho ideal para você?'
      />
      <div className={style.faixa}>
        <div className={style.wrapper}>
          <section className={style.containerGeral}>
            <form onSubmit={handleCalcularBTU} className={style.form}>
              <h3 className={style.tituloForm}>
                Características do cômodo ou sala
              </h3>

              <div className={style.inputsNumeros}>
                <div className={style.inputNumero}>
                  <label htmlFor='comprimento'>Comprimento (m)</label>
                  <input
                    type='number'
                    id='comprimento'
                    className={style.inputNumeroTag}
                    placeholder='Insira o comprimento'
                    maxLength='10'
                    value={comprimento}
                    onChange={(event) => setComprimento(event.target.value)}
                  />
                </div>
                <div className={style.inputNumero}>
                  <label htmlFor='largura'>Largura (m)</label>
                  <input
                    type='number'
                    id='largura'
                    className={style.inputNumeroTag}
                    value={largura}
                    placeholder='Insira a largura'
                    onChange={(event) => setLargura(event.target.value)}
                  />
                </div>
                <p className={style.areaTotal}>
                  Área total: {comprimento && largura ? area : 0} m²
                </p>
              </div>

              <div className={style.radioPessoasContainer}>
                <div className={style.radioContainer}>
                  <p className={style.radioEscolhasTitulo}>Incidência solar</p>
                  <div className={style.inputRadios}>
                    <div className={style.inputRadioLabel}>
                      <input
                        type='radio'
                        value='pouca'
                        id='pouca'
                        checked={incidenciaSolar === 'pouca'}
                        // classname que faz a mesma verificação de checked pra colocar
                        // a bolinha no meio via css
                        onChange={({ target }) => {
                          setIncidenciaSolar(target.value);
                        }}
                      />
                      <label htmlFor='pouca' className={style.radioLabel}>
                        Pouco sol
                      </label>
                    </div>

                    <div className={style.inputRadioLabel}>
                      <input
                        type='radio'
                        id='muita'
                        value='muita'
                        checked={incidenciaSolar === 'muita'}
                        onChange={({ target }) => {
                          setIncidenciaSolar(target.value);
                        }}
                      />
                      <label htmlFor='muita' className={style.radioLabel}>
                        Muito sol
                      </label>
                    </div>
                  </div>
                </div>

                <div
                  className={`${style.inputNumero} ${style.quantidadePessoas}`}
                >
                  <label htmlFor='pessoas'>Quantidade de Pessoas</label>
                  <input
                    type='number'
                    id='pessoas'
                    className={style.inputNumeroTag}
                    value={quantidadePessoas}
                    placeholder='Quantidade de usuários'
                    onChange={(event) =>
                      setQuantidadePessoas(event.target.value)
                    }
                  />
                </div>
              </div>

              <button className={style.botao}>Calcular BTU</button>
            </form>

            <div className={style.divisor} />

            <section className={style.rightSide} ariaLabel='Resultado em BTUs'>
              {!resultado ? (
                <>
                  <div>
                    <p className={style.resultadoTitulo}>
                      Preencha os campos e pressione Calcular BTU
                    </p>
                    <p className={style.resultadoTexto}>
                      British Thermal Unit é o que significa BTU, ou, na
                      tradução livre, Unidade Térmica Britânica. É, portanto,
                      uma unidade de medida de potência de refrigeração de um
                      ar-condicionado.
                    </p>
                    <p className={style.resultadoTexto}>
                      Com esse teste você saberá quantos BTUs são necessários
                      para o cômodo especificado
                    </p>
                  </div>

                  <div className={style.buttonContainer}>
                    <ButtonWithArrow
                      rota='/contato'
                      nomePagina='Contato'
                      text='Entre em contato'
                    />
                  </div>
                </>
              ) : (
                <>
                  <div>
                    <p className={style.resultadoTitulo}>Resultado:</p>
                    <span className={style.resultadoNumero}>
                      {resultado} BTU
                    </span>

                    <p className={style.resultadoTexto}>
                      Essa é a faixa de BTU (Unidade Térmica Britânica)
                      recomendada para a área especificada!
                    </p>
                    <p className={style.resultadoTexto}>
                      Você pode procurar um aparelho com a potência especificada
                      em lojas variadas, ou nos consultar sobre o aparelho
                      apropriado e agendar uma instalação.
                    </p>
                  </div>

                  <div className={style.buttonContainer}>
                    <ButtonWithArrow
                      rota='/contato'
                      nomePagina='Contato'
                      text='Entre em contato'
                    />
                  </div>
                </>
              )}
              {/* <div>
                {incidenciaSolar && <p>Incidência solar: {incidenciaSolar}</p>}
                {quantidadePessoas && (
                  <p>Quantiade de pessoas: {quantidadePessoas}</p>
                )}
              </div> */}
            </section>
          </section>
        </div>
      </div>
    </main>
  );
};

export default PaginaAparelhoIdeal;
