import React from 'react';

const ValoresIcon = ({ darkVersion }) => {
  return (
    <svg
      width='96'
      height='96'
      viewBox='0 0 96 96'
      fill='current'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M0 72V66.7C0 64.1 1.38333 62 4.15 60.4C6.91667 58.8 10.5333 58 15 58C15.8 58 16.5833 58.0167 17.35 58.05C18.1167 58.0833 18.8667 58.1667 19.6 58.3C19.0667 59.4333 18.6667 60.6 18.4 61.8C18.1333 63 18 64.2667 18 65.6V72H0ZM24 72V65.6C24 61.2667 26.2167 57.75 30.65 55.05C35.0833 52.35 40.8667 51 48 51C55.2 51 61 52.35 65.4 55.05C69.8 57.75 72 61.2667 72 65.6V72H24ZM78 72V65.6C78 64.2667 77.8667 63 77.6 61.8C77.3333 60.6 76.9333 59.4333 76.4 58.3C77.1333 58.1667 77.8833 58.0833 78.65 58.05C79.4167 58.0167 80.2 58 81 58C85.4667 58 89.0833 58.8 91.85 60.4C94.6167 62 96 64.1 96 66.7V72H78ZM15 55C13.0667 55 11.4167 54.3167 10.05 52.95C8.68333 51.5833 8 49.9333 8 48C8 46.0667 8.68333 44.4167 10.05 43.05C11.4167 41.6833 13.0667 41 15 41C16.9333 41 18.5833 41.6833 19.95 43.05C21.3167 44.4167 22 46.0667 22 48C22 49.9333 21.3167 51.5833 19.95 52.95C18.5833 54.3167 16.9333 55 15 55ZM81 55C79.0667 55 77.4167 54.3167 76.05 52.95C74.6833 51.5833 74 49.9333 74 48C74 46.0667 74.6833 44.4167 76.05 43.05C77.4167 41.6833 79.0667 41 81 41C82.9333 41 84.5833 41.6833 85.95 43.05C87.3167 44.4167 88 46.0667 88 48C88 49.9333 87.3167 51.5833 85.95 52.95C84.5833 54.3167 82.9333 55 81 55ZM48 48C44.6667 48 41.8333 46.8333 39.5 44.5C37.1667 42.1667 36 39.3333 36 36C36 32.6667 37.1667 29.8333 39.5 27.5C41.8333 25.1667 44.6667 24 48 24C51.3333 24 54.1667 25.1667 56.5 27.5C58.8333 29.8333 60 32.6667 60 36C60 39.3333 58.8333 42.1667 56.5 44.5C54.1667 46.8333 51.3333 48 48 48Z'
        fill={darkVersion ? '#04386A' : '#15AFD0'}
      />
    </svg>
  );
};

export default ValoresIcon;
