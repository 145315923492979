import React, { useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import introducao from './Introducao.module.css';
import empresas from './EmpresasParceiras.module.css';
import aparelhoIdeal from './AparelhoIdeal.module.css';
import Slides from './Slides';
import imagemArCondicionado from '../../assets/PaginaInicial/ar-condicionado.jpg';
import empresaYazigi from '../../assets/PaginaInicial/empresa-yazigi.png';
import empresaFacis from '../../assets/PaginaInicial/empresa-facis.png';
import empresaMajstra from '../../assets/PaginaInicial/empresa-majstra.png';
import imagemManutencao from '../../assets/PaginaInicial/manutencao.png';
import ButtonWithArrow from '../Common/ButtonWithArrow';

const PaginaInicial = ({ titulo, descricao }) => {
  const navigate = useNavigate();

  const navegarAoClique = (rota) => {
    navigate(rota);
  };

  React.useEffect(() => {
    document.title = 'Refrisolution - ' + titulo;
    document
      .querySelector("meta[name='description']")
      .setAttribute('content', descricao);
  }, [titulo, descricao]);

  const introducaoTitulo = useRef();
  const introducaoTexto = useRef();
  const introducaoBotoes = useRef();
  const introducaoImagem = useRef();
  const empresasImagens = useRef();

  React.useEffect(() => {
    introducaoTitulo.current.classList.add('animarDoTopo300');
    introducaoTexto.current.classList.add('animarDoTopo500');
    introducaoBotoes.current.classList.add('animarDoTopo500');
    introducaoImagem.current.classList.add('animarEntrada500');
    empresasImagens.current.classList.add('animarDoTopo300');
  }, []);

  return (
    <main>
      <section className={introducao.introducaoContainer}>
        <div className={introducao.ladoEsquerdo}>
          <h1 ref={introducaoTitulo} className={introducao.titulo}>
            Soluções em <strong>climatização</strong> e{' '}
            <strong>refrigeração</strong> em Sorocaba
          </h1>
          <p ref={introducaoTexto} className={introducao.paragrafo}>
            Fazemos instalação, manutenção e higienização de sistemas com ar
            condicionado, VRF, cortina de ar, chillers, ACP, exaustores
            industriais e mais. Além disso, elaboramos e emitimos{' '}
            <strong>PMOC!</strong>
          </p>

          <div ref={introducaoBotoes} className={introducao.botoes}>
            <button
              className={introducao.botaoPreenchido}
              aria-label='Ir para página contato'
              onClick={() => navegarAoClique('/contato')}
            >
              Entrar em contato
            </button>
            <button
              className={introducao.botaoGhost}
              aria-label='Ir para página PMOC'
              onClick={() => navegarAoClique('/pmoc')}
            >
              O que é PMOC?
            </button>
          </div>
        </div>

        <img
          className={introducao.imagemArCondicionado}
          height='344px'
          width='506px'
          ref={introducaoImagem}
          src={imagemArCondicionado}
          alt='Foto um de ar condicionado'
        />
      </section>

      <div className={empresas.background}>
        <div className='linhaDivisoriaGeral' />
        <section className={empresas.container}>
          <h3 className={empresas.titulo}>Empresas parceiras</h3>
          <ul ref={empresasImagens} className={empresas.listaImagens}>
            <li>
              <a
                href='https://www.yazigi.com.br/'
                rel='noreferrer'
                target='_blank'
                className={empresas.imagemAnchor}
              >
                <img
                  className={empresas.imagem}
                  src={empresaYazigi}
                  alt='Empresa Yazigi'
                  height='100px'
                  width='100px'
                />
              </a>
            </li>
            <li>
              <a
                href='https://www.facis.com.br/'
                rel='noreferrer'
                target='_blank'
                className={empresas.imagemAnchor}
              >
                <img
                  className={empresas.imagem}
                  src={empresaFacis}
                  alt='Empresa Facis'
                  height='100px'
                  width='100px'
                />
              </a>
            </li>
          </ul>
        </section>
        <div className='linhaDivisoriaGeral' />
      </div>

      <Slides />

      <div className={aparelhoIdeal.background}>
        <div className={aparelhoIdeal.backgroundOutline}>
          <section className={aparelhoIdeal.container}>
            <img
              className={aparelhoIdeal.imagem}
              src={imagemManutencao}
              alt='Técnico fazendo manutenção'
            />
            <div>
              <span className={aparelhoIdeal.subTitulo}>RECOMENDAÇÃO</span>
              <h3 className={aparelhoIdeal.titulo}>
                Quer saber qual o aparelho ideal para você?
              </h3>
              <p className={aparelhoIdeal.texto}>
                Faça um breve teste para ver quantos{' '}
                <span className={aparelhoIdeal.semiBold}>BTUs</span> são
                necessários para a climatização de algum cômodo com base em sua
                área (m²) e incidência solar.
              </p>
              <div className={aparelhoIdeal.botao}>
                <ButtonWithArrow
                  rota='aparelho-ideal'
                  nomePagina='Aparelho Ideal'
                  text='Faça o teste!'
                />
              </div>
            </div>
          </section>
        </div>
      </div>
    </main>
  );
};

export default PaginaInicial;
