import { BrowserRouter, Route, Routes } from 'react-router-dom';
import './App.css';
import Header from './componentes/Common/Header';
import Footer from './componentes/Common/Footer';
import PaginaInicial from './componentes/PaginaInicial/PaginaInicial';
import PaginaSobreNos from './componentes/PaginaSobreNos/PaginaSobreNos';
import PaginaPMOC from './componentes/PaginaPMOC/PaginaPMOC';
import PaginaContato from './componentes/PaginaContato/PaginaContato';
import WhatsAppIcon from './componentes/Common/WhatsAppIcon';
import { Analytics } from '@vercel/analytics/react';
import PaginaAparelhoIdeal from './componentes/PaginaAparelhoIdeal/PaginaAparelhoIdeal';

function App() {
  return (
    <>
      <BrowserRouter>
        <Header />
        <Routes>
          <Route
            path='/'
            element={
              <PaginaInicial
                titulo='Página inicial'
                descricao='Soluções em climatização e refrigeração em Sorocaba. Fazemos instalação, manutenção e higienização de sistemas com ar condicionado, VRF, cortina de ar, chillers, ACP, exaustores industriais e mais. Além disso, elaboramos e emitimos PMOC!'
              />
            }
          />
          <Route
            path='sobre-nos'
            element={
              <PaginaSobreNos
                titulo='Sobre nós'
                descricao='Empresa de Sorocaba, atuando na instalação e manutenção de equipamentos de ar
                condicionado. Garantindo o respeito ao meio ambiente e a qualidade no tratamento do ar.
                Além da elaboração e execução do PMOC, expedimos o Termo de Responsabilidade Técnica (TRT) correspondente junto ao CFT/CRT-SP (Conselho Federal e regional dos Técnicos Industriais de São Paulo), assegurando a conformidade às normas técnicas.'
              />
            }
          />
          <Route
            path='pmoc'
            element={
              <PaginaPMOC
                titulo='PMOC'
                descricao='O PMOC estabelece os procedimentos e periodicidade com que se deve verificar a integridade e o estado de limpeza e conservação dos sistemas de climatização. Emitimos PMOC!'
              />
            }
          />
          <Route
            path='contato'
            element={
              <PaginaContato
                titulo='Contato'
                descricao='Contate-nos através de nossos canais de contato! Instagram, WhatsApp, telefone e e-mail.'
              />
            }
          />
          <Route
            path='aparelho-ideal'
            element={
              <PaginaAparelhoIdeal
                titulo='Meu Aparelho Ideal'
                descricao='Faça um breve teste para ver quantos BTUs são necessários para a climatização de algum cômodo com base em sua área (m²) e incidência solar.'
              />
            }
          />
        </Routes>
        <Footer />
        <WhatsAppIcon />
      </BrowserRouter>
      <Analytics />
    </>
  );
}

export default App;
