import React, { useRef } from 'react';
import style from './TituloPagina.module.css';

const TituloPagina = ({ texto, textoEnfase }) => {
  const titulo = useRef();

  React.useEffect(() => {
    titulo.current.classList.add('animarDoTopo300');
  }, []);

  return (
    <h1 className={style.titulo} ref={titulo}>
      {texto}
      <span className={style.enfase}>{textoEnfase}</span>
    </h1>
  );
};

export default TituloPagina;
