import React, { useRef } from 'react';
import style from './Slides.module.css';
import MissaoIcon from '../Common/svg/MissaoIcon';
import VisaoIcon from '../Common/svg/VisaoIcon';
import ValoresIcon from '../Common/svg/ValoresIcon';
import ButtonWithArrow from '../Common/ButtonWithArrow';

const slidesObject = [
  {
    titulo: 'Missão',
    icone: <MissaoIcon />,
    texto:
      'Proporcionar conforto em climatização, assim como, soluções técnicas específicas, através de processos ágeis, seguros e eficazes, com o objetivo de superar quaisquer expectativas que os nossos clientes possam ter.',
  },
  {
    titulo: 'Visão',
    icone: <VisaoIcon />,
    texto:
      'Ser referência em soluções em Sistemas de Ar Condicionado, além de oferecer um serviço aos clientes, que se diferencie pela qualidade e confiabilidade.',
  },
  {
    titulo: 'Valores',
    icone: <ValoresIcon />,
    texto: 'Respeito, seriedade, profissionalismo e inovação.',
    texto2:
      'Assim buscamos nos diferenciar, aplicando constantemente serviços de excelência que se adéquam aos mercados mais exigentes.',
  },
];

const ProgressCircle = ({ filled }) => {
  return (
    <svg
      width='10'
      height='10'
      viewBox='0 0 10 10'
      fill='current'
      xmlns='http://www.w3.org/2000/svg'
    >
      <rect
        style={{ transition: '300ms' }}
        y='10'
        width='10'
        height='10'
        rx='5'
        transform='rotate(-90 0 10)'
        fill={filled ? '#5BB4DA' : '#C5C5C5'}
      />
    </svg>
  );
};

const NextSlideArrow = () => {
  return (
    <svg
      width='22'
      height='34'
      viewBox='0 0 22 34'
      fill='current'
      xmlns='http://www.w3.org/2000/svg'
      className={style.arrow}
    >
      <path
        d='M0.735226 29.1323L13.9381 16.8722L14.3326 16.5058L13.9381 16.1394L0.735226 3.87926L4.18623 0.681641L21.2643 16.5058L4.18623 32.3299L0.735226 29.1323Z'
        fill='#3290B8'
        stroke='white'
      />
    </svg>
  );
};

const Slides = () => {
  const [segundosPassados, setSegundosPassados] = React.useState(0);
  const [slideAtual, setSlideAtual] = React.useState(0);

  React.useEffect(() => {
    const slidesInterval = setInterval(() => {
      setSegundosPassados((segundosPassados) => segundosPassados + 1);
    }, 1000);
    return () => clearInterval(slidesInterval);
  }, []);

  React.useEffect(() => {
    if (segundosPassados === 7) {
      setSlideAtual(1);
    }

    if (segundosPassados === 14) {
      setSlideAtual(2);
    }

    if (segundosPassados === 21) {
      setSlideAtual(0);
      setSegundosPassados(0);
    }
  }, [segundosPassados]);

  const slideTitulo = useRef();
  const slideIcone = useRef();
  const slideTexto = useRef();

  React.useEffect(() => {
    // console.log('slide passado');
    slideTitulo.current.classList.add('animarDireitaEsquerda300');
    slideTexto.current.classList.add('animarDireitaEsquerda300');
    slideIcone.current.classList.add('animarEntrada500');
    const timer = setTimeout(() => {
      slideTitulo.current.classList.remove('animarDireitaEsquerda300');
      slideTexto.current.classList.remove('animarDireitaEsquerda300');
      slideIcone.current.classList.remove('animarEntrada500');
    }, 200);
    return () => clearTimeout(timer);
  }, [slideAtual]);

  const handleSkipSlide = () => {
    if (slideAtual === 0) {
      setSegundosPassados(7);
    } else if (slideAtual === 1) {
      setSegundosPassados(14);
    } else if (slideAtual === 2) {
      setSegundosPassados(21);
    }
  };

  return (
    <>
      {/* <p>contador: {segundosPassados}</p> */}
      {/* <p>slideAtual: {slideAtual}</p> */}
      <section className={style.container}>
        <div className={style.topContent}>
          <h3 ref={slideTitulo} className={`${style.titulo} ${style.animar}`}>
            {slidesObject[slideAtual].titulo}
          </h3>
          <div className={style.circles}>
            <ProgressCircle filled={slideAtual === 0} />
            <ProgressCircle filled={slideAtual === 1} />
            <ProgressCircle filled={slideAtual === 2} />
          </div>
        </div>
        <div className={style.innerContent}>
          <div
            ref={slideIcone}
            className={style.icone}
            alt={`Ícone ${slidesObject[slideAtual].titulo}`}
          >
            {slidesObject[slideAtual].icone}
          </div>
          <div ref={slideTexto} className={style.innerText}>
            <p>{slidesObject[slideAtual].texto}</p>
            {slidesObject[slideAtual]?.texto2 ? (
              <p>{slidesObject[slideAtual]?.texto2}</p>
            ) : null}
          </div>
          <div className={style.arrowGhostDiv} onClick={handleSkipSlide}>
            <NextSlideArrow />
          </div>
        </div>
        <div className={style.buttonContainer}>
          <ButtonWithArrow
            rota={'/sobre-nos'}
            nomePagina={'Sobre nós'}
            text={'Mais sobre a Refrisolution'}
          />
        </div>
      </section>
    </>
  );
};

export default Slides;
