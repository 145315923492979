import React, { useRef } from 'react';
import TituloPagina from '../Common/TituloPagina';
import style from './PaginaPMOC.module.css';
import brasao from '../../assets/PaginaPMOC/brasao.png';
import documentoAssinado from '../../assets/PaginaPMOC/documento.jpg';
import ButtonWithArrow from '../Common/ButtonWithArrow';

const PaginaPMOC = ({ titulo, descricao }) => {
  React.useEffect(() => {
    document.title = 'Refrisolution - ' + titulo;
    document
      .querySelector("meta[name='description']")
      .setAttribute('content', descricao);
  }, [titulo, descricao]);

  const textoFaixa = useRef();
  const textoDescricao = useRef();

  React.useEffect(() => {
    textoFaixa.current.classList.add('animarDoTopo300');
    textoDescricao.current.classList.add('animarDoTopo500');
  }, []);

  return (
    <main>
      <TituloPagina texto='O que é ' textoEnfase='PMOC?' />

      <section className={style.faixa}>
        <div className={style.faixaLeftSide}>
          <h3 className={style.faixaPmoc} ref={textoFaixa}>
            <span className={style.semibold}>P</span>lano de{' '}
            <span className={style.semibold}>M</span>anutenção,{' '}
            <span className={style.semibold}>O</span>peração e{' '}
            <span className={style.semibold}>C</span>ontrole
          </h3>
          <h2 className={style.faixaPmocTexto} ref={textoFaixa}>
            Apesar de pouco conhecido, o{' '}
            <span className={style.semibold}>PMOC</span> é uma{' '}
            <span className={style.semibold}>obrigação legal</span> e visa
            garantir o bom estado do sistema de climatização e saúde dos
            ocupantes.
          </h2>
        </div>
        <img
          src={brasao}
          className={style.brasao}
          alt='Brasão Brasil'
          width={'119px'}
          height={'125px'}
        />
      </section>

      <section className={style.descricaoContainer}>
        <div className={style.descricaoLeftSide} ref={textoDescricao}>
          <p className={style.descricaoUm}>
            O <span className={style.semibold}>PMOC</span> estabelece os
            procedimentos e periodicidade com que se deve verificar a
            integridade e o estado de limpeza e conservação dos sistemas de
            climatização
          </p>
          <p className={style.descricaoDois}>
            A criação desse plano é de obrigação legal, conforme a{' '}
            <span className={style.semibold}>Lei n° 13.58 9/2018</span>, para
            todos os edifícios de uso público e coletivo e também de uso
            restrito que possuam ambientes climatizados artificialmente. O{' '}
            <span className={style.semibold}>PMOC</span> visa garantir a{' '}
            <span className={style.semibold}>higiene</span> dos equipamentos e
            estruturas envolvidos no processo de climatização, para que estes
            estejam{' '}
            <span className={style.semibold}>
              livres de fungos, bactérias, ácaros, contaminantes e material
              particulado
            </span>
            .
          </p>
          <div className={style.botaoContainer}>
            <ButtonWithArrow
              rota={'/contato'}
              text={'Entre em contato'}
              nomePagina={'Contato'}
            />
          </div>
        </div>
        <img
          className={style.imagem}
          src={documentoAssinado}
          alt={'Documento sendo assinado'}
        />
      </section>
    </main>
  );
};

export default PaginaPMOC;
