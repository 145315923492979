import React from 'react';
import Icon from '../../assets/Common/whatsapp-icon.png';
import styles from './WhatsAppIcon.module.css';

const WhatsAppIcon = () => {
  return (
    <a
      className={styles.container}
      href='https://wa.me/5515997575635'
      aria-label='Chame-nos no WhatsApp'
    >
      <img
        className={styles.icon}
        height={'48px'}
        width={'48px'}
        src={Icon}
        alt='Ícone WhatsApp'
      />
    </a>
  );
};

export default WhatsAppIcon;
