import React from 'react';
import { useNavigate } from 'react-router-dom';
import style from './ButtonWithArrow.module.css';

const Arrow = () => {
  return (
    <svg
      width='8'
      height='12'
      viewBox='0 0 8 12'
      fill='current'
      xmlns='http://www.w3.org/2000/svg'
      className={style.arrow}
    >
      <path
        d='M1.3748 11.0497L0.299805 10.0639L5.2498 5.52485L0.299805 0.985762L1.3748 0L7.3998 5.52485L1.3748 11.0497Z'
        fill='#ECF4FF'
      />
    </svg>
  );
};

const ButtonWithArrow = ({ text, rota, nomePagina }) => {
  const navigate = useNavigate();

  const navegarAoClique = () => {
    window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
    navigate(rota);
  };

  return (
    <button
      aria-label={`Ir para a página ${nomePagina}`}
      className={style.button}
      onClick={() => navegarAoClique()}
    >
      <p>{text}</p>
      <Arrow />
    </button>
  );
};

export default ButtonWithArrow;
