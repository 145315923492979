import React, { useRef } from 'react';
import { Link, NavLink } from 'react-router-dom';
import styles from './Header.module.css';
import LogoRefrisolution from './svg/LogoRefrisolution';

const IconMenuHamburguer = () => {
  return (
    <svg
      width='27'
      height='27'
      viewBox='0 0 27 27'
      fill='current'
      xmlns='http://www.w3.org/2000/svg'
    >
      <g clipPath='url(#clip0_216_6)'>
        <path
          d='M3.26172 19.565H22.8269V17.391H3.26172V19.565ZM3.26172 14.1302H22.8269V11.9563H3.26172V14.1302ZM3.26172 6.52148V8.6954H22.8269V6.52148H3.26172Z'
          fill='#ECF4FF'
        />
      </g>
      <defs>
        <clipPath id='clip0_216_6'>
          <rect width='26.087' height='26.087' fill='white' />
        </clipPath>
      </defs>
    </svg>
  );
};

const IconFechar = () => {
  return (
    <svg
      width='30'
      height='30'
      viewBox='0 0 30 30'
      fill='current'
      xmlns='http://www.w3.org/2000/svg'
    >
      <g clipPath='url(#clip0_234_18)'>
        <path
          d='M23.75 8.0125L21.9875 6.25L15 13.2375L8.0125 6.25L6.25 8.0125L13.2375 15L6.25 21.9875L8.0125 23.75L15 16.7625L21.9875 23.75L23.75 21.9875L16.7625 15L23.75 8.0125Z'
          fill='#666666'
        />
      </g>
      <defs>
        <clipPath id='clip0_234_18'>
          <rect width='30' height='30' fill='white' />
        </clipPath>
      </defs>
    </svg>
  );
};

const Header = () => {
  const [menuActive, setMenuActive] = React.useState(false);

  const modalBackground = useRef();
  const modal = useRef();

  React.useEffect(() => {
    if (menuActive === true) {
      modalBackground.current.classList.add('animarEntrada200');
      modal.current.classList.add('animarDireitaEsquerda300');
      document.body.style.overflow = 'hidden';
    }

    if (menuActive === false) {
      document.body.style.overflow = 'auto';
    }
  }, [menuActive]);

  const estiloPaginaAtiva = {
    color: '#121212',
    fontWeight: '500',
  };

  const handleClickForaModal = ({ target }) => {
    if (target.id === 'foraModal') {
      setMenuActive(false);
    }
  };

  const handleClickMudarPaginaModal = () => {
    modalBackground.current.classList.add('animarSaida200');
    setTimeout(() => {
      setMenuActive(false);
    }, 200);
  };

  const handleClickFecharModal = () => {
    setMenuActive(false);
  };

  return (
    <header className={styles.headerBackground}>
      <nav aria-label='Navegação' className={styles.navContainer}>
        <Link to='/' aria-label='Página inicial' className={styles.logo}>
          <LogoRefrisolution />
          <span className={styles.logoText}>Refrisolution</span>
        </Link>

        <div className={styles.containerPaginas}>
          <NavLink
            className={styles.pagina}
            end
            to='/'
            aria-label='Página Inicial'
            activeStyle={estiloPaginaAtiva}
          >
            Página Inicial
          </NavLink>

          <NavLink
            className={styles.pagina}
            to='sobre-nos'
            aria-label='Página Sobre Nós'
            activeStyle={estiloPaginaAtiva}
          >
            Sobre Nós
          </NavLink>

          <NavLink
            className={styles.pagina}
            to='pmoc'
            aria-label='Página PMOC'
            activeStyle={estiloPaginaAtiva}
          >
            PMOC
          </NavLink>

          <NavLink
            className={styles.pagina}
            to='Contato'
            aria-label='Página Contato'
            activeStyle={estiloPaginaAtiva}
          >
            Contato
          </NavLink>
        </div>

        <button
          role='navigation'
          aria-label='Abrir menu mobile'
          className={styles.menuMobile}
          onClick={() => setMenuActive(!menuActive)}
        >
          <p className={styles.menuTexto}>Menu</p>
          <div className={styles.botao}>
            <IconMenuHamburguer />
          </div>
        </button>

        {menuActive ? (
          <div
            className={styles.modalBackground}
            onClick={handleClickForaModal}
            ref={modalBackground}
            // onTouchStart={handleClickForaModal}
            id='foraModal'
          >
            <section
              ref={modal}
              className={styles.modal}
              aria-label='Menu Mobile'
            >
              <div className={styles.modalTop}>
                <LogoRefrisolution />
                <div
                  className={styles.iconFecharContainer}
                  aria-label='Fechar menu mobile'
                  onClick={handleClickFecharModal}
                >
                  <IconFechar />
                </div>
              </div>
              <nav className={styles.modalPaginas}>
                <NavLink
                  onClick={handleClickMudarPaginaModal}
                  className={styles.paginaMobile}
                  end
                  to='/'
                  aria-label='Página Inicial'
                  activeStyle={estiloPaginaAtiva}
                >
                  Página Inicial
                </NavLink>
                <div className={styles.modalDivisor} />
                <NavLink
                  onClick={handleClickMudarPaginaModal}
                  className={styles.paginaMobile}
                  to='sobre-nos'
                  aria-label='Página Sobre Nós'
                  activeStyle={estiloPaginaAtiva}
                >
                  Sobre Nós
                </NavLink>
                <div className={styles.modalDivisor} />
                <NavLink
                  onClick={handleClickMudarPaginaModal}
                  className={styles.paginaMobile}
                  to='pmoc'
                  aria-label='Página PMOC'
                  activeStyle={estiloPaginaAtiva}
                >
                  PMOC
                </NavLink>
                <div className={styles.modalDivisor} />
                <NavLink
                  onClick={handleClickMudarPaginaModal}
                  className={styles.paginaMobile}
                  to='contato'
                  aria-label='Página Contato'
                  activeStyle={estiloPaginaAtiva}
                >
                  Contato
                </NavLink>
                <div className={styles.modalDivisor} />
                <NavLink
                  onClick={handleClickMudarPaginaModal}
                  className={styles.paginaMobile}
                  to='aparelho-ideal'
                  aria-label='Página Aparelho Ideal'
                  activeStyle={estiloPaginaAtiva}
                >
                  Aparelho Ideal
                </NavLink>
              </nav>
            </section>
          </div>
        ) : null}
      </nav>
      <div className='linhaDivisoriaGeral' />
    </header>
  );
};

export default Header;
